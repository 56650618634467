import React, { FC, useContext } from "react";
import { Text, TextProps, ResponsiveContext } from "grommet";

interface Props {
  buyerNames: string[];
  textProps?: TextProps;
}

const ClaimInfo: FC<Props> = ({ buyerNames, textProps }) => {
  const isMobile = useContext(ResponsiveContext) === "small";

  return (
    <>
      {buyerNames.map((name, idx, arr) => {
        const isFirst = idx !== 0;
        const isLast = idx + 1 === arr.length;
        const hasMultipleClaimants = arr.length > 1;
        return (
          <React.Fragment key={name}>
            {isFirst && !isLast ? ", " : isLast && hasMultipleClaimants ? " and " : ""}
            <Text {...textProps} size={textProps?.size || isMobile ? "xsmall" : undefined}>
              {name}
            </Text>
          </React.Fragment>
        );
      })}
    </>
  );
};

export function toNameList(names: string[]) {
  return names.reduce((nameList, name, idx, arr) => {
    const isFirst = idx !== 0;
    const isLast = idx + 1 === arr.length;
    const hasMultipleClaimants = arr.length > 1;
    let txt = "";
    txt = txt + (isFirst && !isLast ? ", " : isLast && hasMultipleClaimants ? " and " : "");
    txt = txt + name;
    return nameList + txt;
  }, "");
}

export default ClaimInfo;
