import { MarginType } from "grommet/utils";
import { Heading, HeadingProps } from "grommet";
import styled, { DefaultTheme } from "styled-components";
import { FC } from "react";

type HeadingType = HeadingProps &
  (
    | Omit<JSX.IntrinsicElements["h1"], "color">
    | Omit<JSX.IntrinsicElements["h2"], "color">
    | Omit<JSX.IntrinsicElements["h3"], "color">
    | Omit<JSX.IntrinsicElements["h4"], "color">
    | Omit<JSX.IntrinsicElements["h5"], "color">
    | Omit<JSX.IntrinsicElements["h6"], "color">
  ) & { limitHeight?: boolean; bold?: boolean; underline?: boolean };

const getMargin = (theme: DefaultTheme, position: "top" | "left" | "right" | "bottom", margin?: MarginType) => {
  type MarginPropertyType = "none" | "hair" | "xxsmall" | "xsmall" | "small" | "medium" | "large" | "xlarge";
  const spacing = theme.global?.edgeSize;
  if (!margin || !spacing) return "0";
  if (typeof margin === "string") return spacing[margin as MarginPropertyType];
  const topMargin = (margin.top || margin.vertical) as MarginPropertyType | undefined;
  const rightMargin = (margin.right || margin.horizontal) as MarginPropertyType | undefined;
  const bottomMargin = (margin.bottom || margin.vertical) as MarginPropertyType | undefined;
  const leftMargin = (margin.left || margin.horizontal) as MarginPropertyType | undefined;
  switch (position) {
    case "top":
      return !!topMargin ? spacing[topMargin] : "0";
    case "right":
      return !!rightMargin ? spacing[rightMargin] : "0";
    case "bottom":
      return !!bottomMargin ? spacing[bottomMargin] : "0";
    case "left":
      return !!leftMargin ? spacing[leftMargin] : "0";
    default:
      return "0";
  }
};

const SLystItemCardHeading = styled(Heading as FC<HeadingType>).attrs(({ level, theme: { dark }, ...props }) => ({
  level: level || 5,
  as: "header",
  color: props.bold ? (dark ? "white" : "black") : undefined,
  ...props,
}))`
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  max-height: ${props => (props.limitHeight ? "48px" : "none")};
  overflow: hidden;
  -webkit-line-clamp: ${props => (props.limitHeight ? 2 : "none")};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-width: none;
  text-decoration: ${props => (props.underline ? "underline" : "none")};

  margin-top: ${({ theme, margin }) => getMargin(theme, "top", margin)};
  margin-right: ${({ theme, margin }) => getMargin(theme, "right", margin)};
  margin-bottom: ${({ theme, margin }) => getMargin(theme, "bottom", margin)};
  margin-left: ${({ theme, margin }) => getMargin(theme, "left", margin)};

  @media (max-width: 767px) {
    font-weight: ${({ bold }) => (bold ? 600 : 400)};
    font-size: 13px;
    white-space: normal;
    line-height: 1.2em;
  }
`;

export default SLystItemCardHeading;
