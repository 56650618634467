import { Box, BoxTypes } from "grommet";
import styled from "styled-components";
import { FC } from "react";

type RoundedCardType = BoxTypes & { muted?: boolean };

export const SRoundedCard = styled(Box as FC<RoundedCardType>).attrs(({ pad, elevation, background, muted, ...props }) => {
  const { dark, global } = props.theme;
  const themeBg = dark ? "dark-1" : "white";
  const bg = !!muted ? global?.colors?.background : themeBg;
  return {
    pad: pad ? pad : "medium",
    background: !!background ? background : bg,
    ...props,
  };
})`
  border-radius: 8px;
`;

export default SRoundedCard;
