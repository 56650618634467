import React from "react";
import { Heading, Paragraph, Box } from "grommet";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../assets/icons/wishlystlogo.svg";
import { useTheme } from "styled-components";

const PrivacyPolicy = () => {
  const { dark, global } = useTheme();
  return (
    <Box pad="medium">
      <Link to="/">
        <Box width="280px" margin={{ bottom: "large" }}>
          <Logo color={dark ? "white" : global?.colors?.brand?.toString() || "black"} />
        </Box>
      </Link>
      <Heading level={2}>Privacy Policy</Heading>
      <Paragraph fill>
        Your privacy is important to us. It is Fluxion Labs Ltd's policy to respect your privacy regarding any information we may collect
        from you across our website, <Link to="/">http://wishlyst.co.uk</Link>, and other sites we own and operate.
      </Paragraph>
      <Paragraph fill>
        We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with
        your knowledge and consent. We also let you know why we’re collecting it and how it will be used.
      </Paragraph>
      <Paragraph fill>
        We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll
        protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or
        modification.
      </Paragraph>
      <Paragraph fill>
        We don’t share any personally identifying information publicly or with third-parties, except when required to by law.
      </Paragraph>
      <Paragraph fill>
        Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and
        practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.
      </Paragraph>
      <Paragraph fill>
        You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with
        some of your desired services.
      </Paragraph>
      <Paragraph fill>
        Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you
        have any questions about how we handle user data and personal information, feel free to contact us.
      </Paragraph>
      <Paragraph fill>This policy is effective as of 10 June 2020.</Paragraph>
    </Box>
  );
};

export default PrivacyPolicy;
