import { ResponsiveContext } from "grommet";
import { functions, auth } from "firebase/app";
import { useState, useContext, useEffect } from "react";

type ProviderType = auth.TwitterAuthProvider | auth.GoogleAuthProvider | auth.FacebookAuthProvider;

const useSocialLogin = () => {
  const updateUserProfile = functions().httpsCallable("updateUserProfile");
  const [isPending, setIsPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isMobile = useContext(ResponsiveContext) === "small";

  const signInWithPopup = (provider: ProviderType) => {
    return auth()
      .signInWithPopup(provider)
      .then(account => {
        if (!account || !account.user) throw Error("no account found");
        if (account.additionalUserInfo?.isNewUser) return updateUserProfile({ uid: account.user.uid });
        return Promise.resolve(undefined as any);
      });
  };

  const signInWithRedirect = (provider: ProviderType) => auth().signInWithRedirect(provider);

  function socialSignIn(provider: ProviderType) {
    setIsPending(true);
    setErrorMessage("");
    const login = isMobile ? signInWithRedirect : signInWithPopup;
    login(provider)
      .catch(err => setErrorMessage(err.message))
      .finally(() => setIsPending(false));
  }

  useEffect(() => {
    auth()
      .getRedirectResult()
      .then(async result => {
        if (result.user && result.additionalUserInfo?.isNewUser) {
          await updateUserProfile({ uid: result.user?.uid });
        }
        setIsPending(false);
      });
  }, [updateUserProfile]);

  return {
    signIn: socialSignIn,
    isPending,
    errorMessage,
  };
};

export default useSocialLogin;
