import { ILyst } from "../@types";

type ClaimFilterType = false | "me" | "claimed" | "unclaimed";
export interface IReducerState {
  activeView: string;
  showClaimedItemsOnly: boolean;
  viewMode: "grid" | "list";
  claimFilter: ClaimFilterType;
  allLysts: {
    [id: string]: ILyst;
  };
  byMe: string[];
  anonymousWishlystUserIds: {
    [wishlystId: string]: string[];
  };
  algoliaUserSearchKey: {
    [wishlystId: string]: {
      pending: boolean;
      key?: string;
    };
  };
}

const initialState: IReducerState = {
  showClaimedItemsOnly: false,
  viewMode: "list",
  claimFilter: false,
  activeView: "",
  allLysts: {},
  byMe: [],
  algoliaUserSearchKey: {},
  anonymousWishlystUserIds: {},
};

export const lystAdded = (lyst: ILyst) => ({
  type: "lysts/ADDED" as "lysts/ADDED",
  payload: lyst,
});
export const lystRemoved = (lystId: string) => ({
  type: "lysts/REMOVED" as "lysts/REMOVED",
  payload: lystId,
});
export const setMyLystsOrder = (order: string[]) => ({
  type: "lysts/MY_LYSTS_ORDER" as "lysts/MY_LYSTS_ORDER",
  payload: order,
});
export const setActiveView = (lystId: string) => ({
  type: "lysts/SET_ACTIVE_VIEW" as "lysts/SET_ACTIVE_VIEW",
  payload: lystId,
});
export const setShowClaimedItemsOnly = (claimedOnly: boolean) => ({
  type: "lysts/SHOW_CLAIMED_ITEMS_ONLY" as "lysts/SHOW_CLAIMED_ITEMS_ONLY",
  payload: claimedOnly,
});
export const setClaimFilter = (claimFilter: ClaimFilterType) => ({
  type: "lysts/SET_CLAIM_FILTER" as "lysts/SET_CLAIM_FILTER",
  payload: claimFilter,
});
export const getSecuredWishlystUserSearchKey = (wishlystId: string) => ({
  type: "lysts/GET_SECURED_KEY" as "lysts/GET_SECURED_KEY",
  payload: wishlystId,
});
export const getSecuredWishlystUserSearchKeySuccess = (wishlystId: string, key: string) => ({
  type: "lysts/GET_SECURED_KEY_SUCCESS" as "lysts/GET_SECURED_KEY_SUCCESS",
  payload: { wishlystId, key },
});

export const setAnonymousUserIdsForWishlyst = (wishlystId: string, ids: string[]) => ({
  type: "lysts/ANONYMOUS_USERS_IDS" as "lysts/ANONYMOUS_USERS_IDS",
  payload: { wishlystId, ids },
});

export const setViewMode = (mode: IReducerState["viewMode"]) => ({
  type: "lysts/SET_VIEW_MODE" as "lysts/SET_VIEW_MODE",
  payload: mode,
});

type TAction =
  | ReturnType<typeof lystAdded>
  | ReturnType<typeof setMyLystsOrder>
  | ReturnType<typeof setActiveView>
  | ReturnType<typeof setShowClaimedItemsOnly>
  | ReturnType<typeof setClaimFilter>
  | ReturnType<typeof getSecuredWishlystUserSearchKey>
  | ReturnType<typeof getSecuredWishlystUserSearchKeySuccess>
  | ReturnType<typeof setAnonymousUserIdsForWishlyst>
  | ReturnType<typeof lystRemoved>
  | ReturnType<typeof setViewMode>;

export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case "lysts/ADDED":
      return { ...state, allLysts: { ...state.allLysts, [action.payload.id]: action.payload } };
    case "lysts/REMOVED": {
      const lists = { ...state.allLysts };
      delete lists[action.payload];
      return { ...state, allLysts: lists };
    }
    case "lysts/MY_LYSTS_ORDER":
      return { ...state, byMe: action.payload };
    case "lysts/SET_ACTIVE_VIEW":
      return { ...state, activeView: action.payload, showClaimedItemsOnly: false };
    case "lysts/SHOW_CLAIMED_ITEMS_ONLY":
      return { ...state, showClaimedItemsOnly: action.payload };
    case "lysts/SET_CLAIM_FILTER":
      return { ...state, claimFilter: action.payload };
    case "lysts/GET_SECURED_KEY":
      return {
        ...state,
        algoliaUserSearchKey: {
          ...state.algoliaUserSearchKey,
          [action.payload]: {
            ...(state.algoliaUserSearchKey[action.payload] || {}),
            pending: true,
          },
        },
      };
    case "lysts/GET_SECURED_KEY_SUCCESS":
      return {
        ...state,
        algoliaUserSearchKey: {
          ...state.algoliaUserSearchKey,
          [action.payload.wishlystId]: {
            ...(state.algoliaUserSearchKey[action.payload.wishlystId] || {}),
            pending: false,
            key: action.payload.key,
          },
        },
      };
    case "lysts/ANONYMOUS_USERS_IDS":
      return {
        ...state,
        anonymousWishlystUserIds: {
          ...state.anonymousWishlystUserIds,
          [action.payload.wishlystId]: action.payload.ids,
        },
      };
    case "lysts/SET_VIEW_MODE":
      return {
        ...state,
        viewMode: action.payload,
      };
    default:
      return state;
  }
}
