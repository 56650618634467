import React, { FC } from "react";
import { Box, Button } from "grommet";
import { Filter, Add } from "grommet-icons";
import GridListing from "../styled-components/grid-listing";
import { ILystItem } from "../@types";
import { useStateSelector } from "../store";
import LystItemOwnerCard from "./lyst-item-owner-card";
import LystItemClaimCard from "./lyst-item-claim-card";
import LystItemHorizontalCard from "./lyst-item-horizontal-card";
import LystItemOwnerHorizontalCard from "./lyst-item-owner-horizontal-card";

interface IProps {
  lystItems: ILystItem[];
  onAddItem?: () => void;
  onClaim: (lystItemId: string) => any;
  onView: (lystItemId: string) => any;
  onFilter?: () => void;
  isLystOwner?: boolean;
  onRemoveClaim?: (lystItemId: string) => any;
}

const LystItemCardGridLayout: FC<IProps> = ({ onFilter, lystItems, onAddItem, onView, onClaim, isLystOwner, onRemoveClaim }) => {
  const claimFilter = useStateSelector(({ lysts }) => lysts.claimFilter);
  const viewMode = useStateSelector(({ lysts }) => lysts.viewMode);
  const account = useStateSelector(({ auth }) => auth.account);
  const showAddButton = isLystOwner && onAddItem && (claimFilter === "unclaimed" || !claimFilter);
  const listViewProps = { style: { margin: "auto", width: "100%", maxWidth: "1000px" } };
  const componentContainerProps = viewMode === "list" ? listViewProps : {};
  const ListingContainer = viewMode === "grid" ? GridListing : Box;
  const listingContainerProps = viewMode === "grid" ? {} : { gap: "small", align: "center" as "center" };

  return (
    <Box {...componentContainerProps}>
      <Box margin={{ bottom: "medium" }} justify="between" direction="row">
        <Box round="full" border overflow="hidden">
          {onFilter && <Button onClick={onFilter} icon={<Filter />} />}
        </Box>
        {showAddButton && (
          <Box round="full" border overflow="hidden">
            <Button hoverIndicator icon={<Add />} onClick={onAddItem} />
          </Box>
        )}
      </Box>

      <ListingContainer {...listingContainerProps}>
        {lystItems.map(lystItem => {
          const { buyerIds = [] } = lystItem;
          const isClaimer = account ? buyerIds.includes(account.uid) : false;
          const removeClaim = onRemoveClaim ? () => onRemoveClaim(lystItem.id) : undefined;
          const cardProps = {
            lystItem,
            onClaim: () => onClaim(lystItem.id),
            onView: () => onView(lystItem.id),
          };
          return (
            <React.Fragment key={lystItem.id}>
              {viewMode === "grid" ? (
                isLystOwner ? (
                  <LystItemOwnerCard {...cardProps} />
                ) : (
                  <LystItemClaimCard {...cardProps} onRemoveClaim={removeClaim} />
                )
              ) : isLystOwner ? (
                <LystItemOwnerHorizontalCard {...cardProps} />
              ) : (
                <LystItemHorizontalCard {...cardProps} claimedByCurrentUser={isClaimer} />
              )}
            </React.Fragment>
          );
        })}
      </ListingContainer>
      <Box height="40px" margin={{ vertical: "large" }}>
        {showAddButton && <Button label="Add another item" alignSelf="center" onClick={onAddItem} />}
      </Box>
    </Box>
  );
};

export default LystItemCardGridLayout;
