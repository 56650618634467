import React, { FC, useContext } from "react";
import { Heading, Text, Box, Paragraph, Anchor, ResponsiveContext } from "grommet";
import { NewWindow } from "grommet-icons";
import { ILystItem } from "../../@types";
import LystItemCardImage from "../lyst-item-card-image";
import { SClippedLinkText } from "../../styled-components/clipped-link";
import { CategoriesContext } from "../../context/categories";

export const ItemDetailContent: FC<{ lystItem: ILystItem }> = ({ lystItem }) => {
  const { name, image, quantity, url, totalClaimed, color, description, categoryId } = lystItem;
  const isMobile = useContext(ResponsiveContext) === "small";
  const { categoryMap } = useContext(CategoriesContext);
  const category = !!categoryId ? categoryMap[categoryId] : null;

  return (
    <>
      <Heading level={4} children={name} margin={{ top: "none", bottom: "small" }} style={{ maxWidth: "none" }} />
      <Box direction="row" gap={isMobile ? "small" : "medium"}>
        <Box width="30%" height={!image?.downloadUrl ? (isMobile ? "100px" : "150px") : undefined} style={{ minWidth: "100px" }}>
          <LystItemCardImage thumburl={image?.downloadUrl} />
        </Box>
        <Box pad={{ vertical: "medium" }}>
          <Text size="small">
            <Text weight="bold" size="small" children={`${quantity} `} />
            <em>wanted</em>, {totalClaimed || 0} <em>claimed</em> {quantity !== totalClaimed ? "so far" : ""}
          </Text>
          {url && (
            <Anchor href={url} target="_blank" margin={{ bottom: "xsmall" }}>
              <Box direction="row" as="span" gap="xsmall" align="center">
                <SClippedLinkText color="#4f93fb">{url}</SClippedLinkText>
                <NewWindow size="small" color="#4f93fb" />
              </Box>
            </Anchor>
          )}
          {color && (
            <Text size="small">
              <Text size="small" weight="bold" children="Colour: " /> {color}
            </Text>
          )}
          {category && (
            <Text size="small">
              <Text size="small" weight="bold" children="Category: " /> {category.label}
            </Text>
          )}
          <Paragraph children={description} fill />
        </Box>
      </Box>
    </>
  );
};

export default ItemDetailContent;
