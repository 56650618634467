import React, { useEffect, useState, FC } from "react";
import { Heading, Button } from "grommet";
import { SearchIndex } from "algoliasearch";
import { useDispatch } from "react-redux";
import { IAlgoliaLystItem, ILyst } from "../../@types";
import { useStateSelector } from "../../store";
import { db } from "../../firebase";
import { ClaimedLystItemsPreviewList } from "../../components/claimed-lyst-items-preview-list";
import { lystAdded } from "../../store/lysts";
import ItemModal from "./item-modal";
import { useHistory } from "react-router-dom";

const ClaimedItemsComponent: FC<{ algoliaIndex: SearchIndex }> = ({ algoliaIndex }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [claimedItems, setClaimedItems] = useState<{ [wishlystId: string]: IAlgoliaLystItem[] }>({});
  const [wishlystIds, setWishlystIds] = useState<{ [wishlystId: string]: number }>({});
  const allLysts = useStateSelector(state => state.lysts.allLysts);
  const [viewItemId, setViewItemId] = useState<string | null>(null);

  useEffect(() => {
    (async function getFacets() {
      const { facets, hits } = await algoliaIndex.search<IAlgoliaLystItem>("", { maxValuesPerFacet: 5 });
      const wishlystIdfacetEntries = facets?.wishlystId ? Object.entries(facets.wishlystId) : undefined;

      if (wishlystIdfacetEntries) {
        const truthyWishlysIdCounts = wishlystIdfacetEntries.reduce((accum, [wishlystId, count]) => {
          return count > 0 ? { ...accum, [wishlystId]: count } : accum;
        }, {} as { [wishlystId: string]: number });
        setWishlystIds(truthyWishlysIdCounts);
      }

      const itemsByWishlyst = hits.reduce((accum, hit) => {
        return { ...accum, [hit.wishlystId]: [...(accum[hit.wishlystId] || []), hit] };
      }, {} as { [wishlystId: string]: IAlgoliaLystItem[] });
      setClaimedItems(itemsByWishlyst);
    })();
  }, [algoliaIndex]);

  useEffect(() => {
    if (Object.keys(wishlystIds).length > 0) {
      const ref = db
        .collection("lysts")
        .where("public", "==", true)
        .where("id", "in", Object.keys(wishlystIds));
      ref.get().then(snapshots => {
        snapshots.docs.forEach(doc => dispatch(lystAdded(doc.data() as ILyst)));
      });
    }
  }, [wishlystIds, dispatch]);

  const viewLyst = (wishlystId: string) => {
    history.push(`/app/wishlysts/${wishlystId}`);
  };

  return (
    <>
      {Object.entries(wishlystIds).map(([wishlystId, count]) => {
        const wishlyst = allLysts[wishlystId];
        return (
          <div key={wishlystId}>
            <Heading level={3} margin={{ bottom: "xxsmall" }} children={wishlyst?.name || wishlystId} />
            <Button size="small" color="accent-1" margin={{ bottom: "small" }} onClick={() => viewLyst(wishlystId)}>
              View wishlyst
            </Button>
            <ClaimedLystItemsPreviewList
              onViewItem={id => setViewItemId(id)}
              showViewAllButton={count > 5}
              lystItems={claimedItems[wishlystId] || []}
            />
          </div>
        );
      })}
      {!!viewItemId && <ItemModal itemId={viewItemId} onClose={() => setViewItemId(null)} />}
    </>
  );
};

export default ClaimedItemsComponent;
