import React, { useEffect, useState, FC } from "react";
import Modal from "../../components/modal";
import ItemDetailContent from "../../components/item-detail-modal-content";
import { db } from "../../firebase";
import { useStateSelector } from "../../store";
import { useDispatch } from "react-redux";
import { fetchItemSuccess } from "../../store/lyst-items";
import { ILystItem } from "../../@types";
import Spinner from "../../components/spinner";

const ItemModal: FC<{ onClose: () => any; itemId: string }> = ({ onClose, itemId }) => {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState();
  const lystItem = useStateSelector(({ lystItems }) => lystItems.allItems[itemId]);

  useEffect(() => {
    setIsFetching(true);
    db.doc(`lystItems/${itemId}`)
      .get()
      .then(snap => {
        dispatch(fetchItemSuccess({ id: snap.id, ...(snap.data() as ILystItem) }));
        setIsFetching(false);
      });
  }, [dispatch, itemId]);

  return (
    <Modal onClose={onClose} title="Item details">
      {isFetching && <Spinner color="brand" />}
      {!!lystItem && <ItemDetailContent lystItem={lystItem} />}
    </Modal>
  );
};

export default ItemModal;
