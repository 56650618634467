import { Text } from "grommet";
import styled from "styled-components";

export const SClippedLinkText = styled(Text)`
  font-size: 12px;
  max-height: 24px;
  overflow: hidden;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;
