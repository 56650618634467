import React, { useState, FC, useContext } from "react";
import { Box, Text, Button, ResponsiveContext, Heading } from "grommet";
import { RouteComponentProps } from "react-router-dom";
import { Helmet } from "react-helmet";
import { auth } from "firebase/app";

import Spinner from "../components/spinner";
import { SAuthContainer } from "../styled-components/auth-container";
import FieldInput from "../components/field-input";

const ForgotPassword: FC<RouteComponentProps> = () => {
  const isMobile = useContext(ResponsiveContext) === "small";
  const [email, setEmail] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [status, setStatus] = useState<"initial" | "success" | "error">("initial");
  const [resetError, setError] = useState("");

  const mobileStyles = { display: "block", height: "auto" };
  const desktopStyles = { minHeight: 560 };

  const sendResetEmail = () => {
    setStatus("initial");
    setShowSpinner(true);
    auth()
      .sendPasswordResetEmail(email)
      .then(() => setStatus("success"))
      .catch(e => {
        setError(e.message);
        setStatus("error");
      })
      .finally(() => setShowSpinner(false));
  };

  const formUI = (
    <>
      <Text margin={{ bottom: "medium" }} size="small">
        Enter the email address for your account. You will recieve a link via email to create a new password
      </Text>
      <FieldInput label="Email" type="email" value={email} onChange={e => setEmail(e.target.value)} />
      <Box direction="row" align="center" justify="center" margin={{ top: "small", bottom: "large" }} gap="small">
        <Button onClick={sendResetEmail} primary label="Send" alignSelf="center" disabled={!email || !!showSpinner} />
        {showSpinner && <Spinner color="brand" />}
      </Box>
      {!!resetError && status === "error" && <Text size="small" color="status-error" children={resetError} />}
    </>
  );

  const successUI = (
    <>
      <Heading level={4} children="Email sent!" />
      <Text margin={{ bottom: "medium" }} size="small">
        If the email address provided exists here, You should recieve an email shortly with a link to reset your password.
      </Text>
    </>
  );

  return (
    <>
      <Helmet children={<title>Forgot password - Wishlyst</title>} />
      <Box fill justify={isMobile ? "start" : "center"} pad={isMobile ? "none" : "large"} style={isMobile ? mobileStyles : desktopStyles}>
        <SAuthContainer>
          <Heading level={2} children="Forgot your password" />
          {status === "success" ? successUI : formUI}
        </SAuthContainer>
      </Box>
    </>
  );
};

export default ForgotPassword;
