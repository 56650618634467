import React, { useRef, useState, useEffect } from "react";
import { functions } from "firebase";
import { Heading, Box } from "grommet";
import { Helmet } from "react-helmet";

import { useStateSelector } from "../../store";
import algoliasearch, { SearchIndex } from "algoliasearch";
import Spinner from "../../components/spinner";
import ClaimedItemsComponent from "./claimed-items.presentational";

const ClaimedItems = () => {
  const { current: getSecureKeyForClaimedItems } = useRef(functions().httpsCallable("claimedItemsSecuredApiKey"));
  const { user } = useStateSelector(({ auth }) => auth);
  const [algoliaIndex, setAlgoliaIndex] = useState<SearchIndex | null>(null);

  useEffect(() => {
    if (!process.env.REACT_APP_ALGOLIA_APPID) return;
    if (!user) return;

    (async function initIndex(algoliaAppId: string) {
      const sessionStorageSecureKeyName = `@wishlyst/USER_${user.id}/CLAIMED_ITEMS`;
      let secureKey = window.sessionStorage.getItem(sessionStorageSecureKeyName);
      if (!secureKey) {
        secureKey = ((await getSecureKeyForClaimedItems()) as { data: string }).data;
        window.sessionStorage.setItem(sessionStorageSecureKeyName, secureKey);
      }
      const client = algoliasearch(algoliaAppId, secureKey);
      const index = client.initIndex("dev_lystItems");
      setAlgoliaIndex(index);
    })(process.env.REACT_APP_ALGOLIA_APPID);
  }, [user, getSecureKeyForClaimedItems]);

  return (
    <>
      <Helmet children={<title>Claimed items and bookmarked lists - Wishlyst</title>} />
      <Heading level={1} margin={{ bottom: "none" }}>
        My claimed items
      </Heading>
      <Heading level={6} margin={{ top: "xsmall", bottom: "medium" }}>
        Items that you have chosen to claim
      </Heading>
      {!algoliaIndex ? (
        <Box pad="large">
          <Spinner color="brand" />
        </Box>
      ) : (
        <ClaimedItemsComponent algoliaIndex={algoliaIndex} />
      )}
    </>
  );
};

export default ClaimedItems;
