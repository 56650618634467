import React, { FC, useContext, useState, useEffect, ReactNode } from "react";
import { Box, RadioButton, ResponsiveContext, Text, Button } from "grommet";
import FieldInput from "../field-input";
import useAlgoliaUserSearch from "./hooks/use-algolia-user-search";
import useAnonymousUserSearch from "./hooks/use-anonymous-user-search";
import usePrevious from "../../hooks/use-previous";
import Spinner from "../spinner";
import { useTheme } from "styled-components";
import { FormClose } from "grommet-icons";
import { slugify } from "../../utils/slugify";

export interface SelectedUser {
  id: string;
  displayName: string;
  _new?: boolean;
}
interface UserSearchProps {
  isSelected: boolean;
  wishlystId: string;
  onSelect: () => any;
  onSelectUser: (user: SelectedUser) => any;
  selectedUsers: SelectedUser[];
  removeSelectedUser: (id: string) => any;
  hideInput: boolean;
}

const UserSearch: FC<UserSearchProps> = ({
  wishlystId,
  isSelected,
  onSelect,
  onSelectUser,
  selectedUsers,
  hideInput,
  removeSelectedUser,
}) => {
  const { dark } = useTheme();
  const isMobile = useContext(ResponsiveContext) === "small";
  const [searchTerms, setSearchTerms] = useState("");
  const previousTerms = usePrevious(searchTerms);
  const { results: userResults, search: query, isPending } = useAlgoliaUserSearch(wishlystId, 5);
  const { results: anonymousUserResults, search: anonymousSearch } = useAnonymousUserSearch(wishlystId, 5);

  useEffect(() => {
    if (previousTerms !== searchTerms) {
      query(searchTerms);
      anonymousSearch(searchTerms);
    }
  });

  const suggestionLabel = (label: string, isNew?: boolean) => (
    <Box hoverIndicator="light-6" pad="small">
      <Text>{label}</Text>
      {isNew && <Text size="small" children="(add new person)" />}
    </Box>
  );

  const userSuggestions = userResults.map(profile => {
    const otherNameForCurrentWishLyst = profile.otherNames?.find(otherName => otherName.wishlystId === wishlystId)?.displayName;
    const displayName = otherNameForCurrentWishLyst || profile.displayName;
    return {
      displayName,
      label: displayName,
      value: profile.objectID,
    };
  });
  const anonUserSuggestions = anonymousUserResults.map(profile => ({
    label: suggestionLabel(profile.displayName),
    value: profile.id,
    displayName: profile.displayName,
  }));
  const suggestions: { label: ReactNode; displayName: string; value: string; _new?: boolean }[] = [
    ...userSuggestions,
    ...anonUserSuggestions,
  ];
  if (searchTerms.length > 0) {
    suggestions.unshift({ label: suggestionLabel(searchTerms, true), value: slugify(searchTerms), displayName: searchTerms, _new: true });
  }

  if (isPending) {
    return <Spinner color="brand" />;
  }

  return (
    <Box direction="row" gap="medium" align="start">
      <RadioButton name="user-search" checked={isSelected} onChange={onSelect} />
      <Box {...(!isMobile ? { flex: "grow" } : {})} style={{ opacity: isSelected ? 1 : 0.7 }}>
        <Text size={isMobile ? "12px" : "16px"}>Search for thier name if they're on Wishlyst</Text>

        {selectedUsers.length > 0 && (
          <Box direction="row" wrap margin={{ vertical: "xsmall" }}>
            {selectedUsers.map(({ id, displayName }) => (
              <Box key={id} pad="xsmall" alignSelf="start" background={dark ? "dark-3" : "light-3"} direction="row" align="center">
                <Text size="small" children={displayName} margin={{ horizontal: "xsmall" }} />
                <Button icon={<FormClose />} plain onClick={() => removeSelectedUser(id)} />
              </Box>
            ))}
          </Box>
        )}

        {!hideInput && (
          <FieldInput
            disabled={!isSelected}
            value={searchTerms}
            onChange={e => setSearchTerms(e.target.value)}
            suggestions={suggestions}
            onSelect={({ suggestion }) => {
              setSearchTerms("");
              onSelectUser({ id: suggestion.value, displayName: suggestion.displayName, _new: suggestion._new });
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default UserSearch;
