import { IStoreUser } from "./../store/users";
import { IRootReducer } from "./../store/combined-reducers";
import { createSelector } from "reselect";
import { ILyst, ILystItem } from "../@types";

type TKeyValue<T> = { [id: string]: T };

const getOrder = (state: IRootReducer): string[] | undefined => state.lystItems.orderByLystId[state.lysts.activeView];
const getLystItemMap = (state: IRootReducer) => state.lystItems.allItems;
const getclaimFilter = (state: IRootReducer) => state.lysts.claimFilter;
const getAccountId = (state: IRootReducer) => state.auth.account?.uid;
const getActiveWishlystId = (state: IRootReducer): string | null => state.lysts.activeView;
const getAnonymousUserIds = (state: IRootReducer) => state.lysts.anonymousWishlystUserIds;
const getAllUsers = (state: IRootReducer) => state.users.allUsers;

export const orderedLystItemsSelector = createSelector(
  getAccountId,
  getOrder,
  getclaimFilter,
  getLystItemMap,
  (accountId, order, claimFilter, itemMap) => {
    if (order) {
      return order.reduce((accum, id) => {
        const item = itemMap[id] as ILystItem | null;
        let add = false;

        if (!!item) {
          const claimedCount = item.totalClaimed || 0;

          if (!claimFilter) add = true;
          if (claimFilter === "claimed" && claimedCount > 0) add = true;
          if (claimFilter === "me" && !!accountId && (item.buyerIds || []).includes(accountId)) add = true;
          if (claimFilter === "unclaimed" && claimedCount === 0) add = true;
        }

        return [...accum, ...(add ? [item] : ([] as any))];
      }, [] as ILystItem[]);
    } else return [];
  }
);

export const anonymousUsersSelector = createSelector(
  getActiveWishlystId,
  getAnonymousUserIds,
  getAllUsers,
  (activeWishlystId, anonymousUserIds, allUsers) => {
    if (!activeWishlystId) return [];
    const anonymousIds = anonymousUserIds[activeWishlystId];
    if (!anonymousIds) return [];

    return anonymousIds.reduce((accum, userId) => {
      const user = allUsers[userId];
      if (user && user.anonymous === true) {
        return [...accum, user as Omit<IStoreUser, "anonymous"> & { anonymous: true }];
      }
      return accum;
    }, [] as Array<Omit<IStoreUser, "anonymous"> & { anonymous: true }>);
  }
);

export const myLystsSelector = createSelector<IRootReducer, TKeyValue<ILyst>, string[], ILyst[]>(
  state => state.lysts.allLysts,
  state => state.lysts.byMe,
  (lystMap, order) => order.reduce((accum, id) => (lystMap[id] ? [...accum, lystMap[id]] : accum), [] as ILyst[])
);

export const algoliaWishLystSubscribersSearchKeySelector = createSelector(
  getActiveWishlystId,
  state => state.lysts.algoliaUserSearchKey,
  (activeWishlystId, allWishlystKeys) => {
    return !!activeWishlystId && allWishlystKeys[activeWishlystId] ? allWishlystKeys[activeWishlystId].key : null;
  }
);

export const algoliaWishLystSubscribersSearchKeyFetchPredicateSelector = createSelector(
  getActiveWishlystId,
  state => state.lysts.algoliaUserSearchKey,
  (activeWishlystId, allWishlystKeys) => {
    return !!activeWishlystId && allWishlystKeys[activeWishlystId] ? allWishlystKeys[activeWishlystId].pending === true : false;
  }
);
