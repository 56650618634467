import { IWishlystAnonymousUser } from "./../../../@types/types";
import { setAnonymousUserIdsForWishlyst } from "./../../../store/lysts";
import { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { useDispatch } from "react-redux";
import { fetchUserSuccess, removeUserSuccess, IStoreUser } from "../../../store/users";
import { useStateSelector } from "../../../store";
import { anonymousUsersSelector } from "../../../selectors";

const useAnonymousUserSearch = (wishlystId: string, maxResults: number) => {
  const dispatch = useDispatch();
  const anonymousUsers = useStateSelector(anonymousUsersSelector);
  const [results, setResults] = useState<IStoreUser[]>([]);

  const search = (terms: string) => {
    if (!terms) return setResults([]);
    const usrs = anonymousUsers.filter(user => user.displayName.indexOf(terms) >= 0);
    setResults(usrs.slice(0, maxResults));
  };

  useEffect(() => {
    db.collection(`lysts/${wishlystId}/anonymousUsers`).onSnapshot(querysnap => {
      querysnap.docChanges().forEach(({ doc, type }) => {
        if (type === "added" || type === "modified") {
          dispatch(
            fetchUserSuccess({ ...(doc.data() as IWishlystAnonymousUser), anonymous: true, id: doc.id, _private: { role: "standard" } })
          );
        }
        if (type === "removed") {
          dispatch(removeUserSuccess(doc.id));
        }
      });
      dispatch(
        setAnonymousUserIdsForWishlyst(
          wishlystId,
          querysnap.docs.map(doc => doc.id)
        )
      );
    });
  }, [wishlystId, dispatch]);

  return { results, search };
};

export default useAnonymousUserSearch;
