import React, { FC, useContext } from "react";
import { Box, ResponsiveContext } from "grommet";
import { IAlgoliaLystItem } from "../@types";
import SRoundedCard from "../styled-components/rounded-card";
import { FabButton } from "../styled-components/fab-button";
import { Next } from "grommet-icons";
import LystItemCardHeading from "../styled-components/lyst-item-card-heading";
import LystItemCardImage from "./lyst-item-card-image";
import { Touchable } from "gestalt";

interface IProps {
  lystItems: IAlgoliaLystItem[];
  showViewAllButton: boolean;
  onViewItem: (itemId: string) => any;
}

export const ClaimedLystItemsPreviewList: FC<IProps> = ({ lystItems, showViewAllButton, onViewItem }) => {
  const isMobile = useContext(ResponsiveContext) === "small";

  return (
    <Box direction="row" gap="medium" wrap={false} overflow="auto" pad={{ vertical: "xxsmall", left: "xxsmall" }}>
      {lystItems.map(lystItem => (
        <SRoundedCard width={isMobile ? "50vw" : "35vw"} pad="small" style={{ maxWidth: 300, flexShrink: 0 }}>
          <Touchable key={lystItem.objectID} onTouch={() => onViewItem(lystItem.objectID)}>
            <Box>
              <Box height={{ min: "120px", max: "250px" }} style={{ height: "20vw" }} round="4px" overflow="hidden">
                <LystItemCardImage thumburl={lystItem.thumbnailUrl || undefined} />
              </Box>
              <LystItemCardHeading
                margin={{ horizontal: "xsmall", vertical: "small" }}
                level={6}
                children={lystItem.name}
                title={lystItem.name}
              />
            </Box>
          </Touchable>
        </SRoundedCard>
      ))}
      {showViewAllButton && (
        <Box width="25vw" style={{ maxWidth: 250, flexShrink: 0 }} align="center" justify="center">
          <FabButton icon={<Next />} label="View all" />
        </Box>
      )}
    </Box>
  );
};
