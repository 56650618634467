import React, { FC, useState, useContext } from "react";
import { ILystItem } from "../../@types";
import SRoundedCard from "../../styled-components/rounded-card";
import { Box, Text, Button, ResponsiveContext } from "grommet";
import LystItemCardImage from "../lyst-item-card-image";
import { Touchable, Icon } from "gestalt";
import SLystItemCardHeading from "../../styled-components/lyst-item-card-heading";
import { useTheme } from "styled-components";

interface Props {
  lystItem: ILystItem;
  onView: () => void;
  onClaim?: () => void;
  claimedByCurrentUser: boolean;
}

export const LystItemHorizontalCard: FC<Props> = ({ lystItem, onClaim, onView, claimedByCurrentUser }) => {
  const { dark } = useTheme();
  const isMobile = useContext(ResponsiveContext) === "small";
  const { totalClaimed, quantity, buyerIds = [] } = lystItem;
  const [isHoverActive, setIsHoverActive] = useState(false);
  const onHover = () => setIsHoverActive(true);
  const onLeave = () => setIsHoverActive(false);
  const fullyClaimed = totalClaimed === lystItem.quantity;
  const partiallyClaimed = totalClaimed > 0 && totalClaimed < lystItem.quantity;
  const ViewBuyerElement = claimedByCurrentUser && onClaim ? Button : Box;
  const claimItem = claimedByCurrentUser || !fullyClaimed ? onClaim : undefined;
  const claimedBySomeoneElse = (partiallyClaimed || fullyClaimed) && !claimedByCurrentUser;
  const fullyClaimedBySomeoneElseText = `This has been taken by ${buyerIds.length > 1 ? "others" : "someone else"}`;
  const claimedByUserAndOthersText = "You and others, have claimed this item";
  const claimedByUserText = "You have ticked this item";
  const leftAvailableText = fullyClaimed ? "No more left to claim" : `${quantity - totalClaimed} left to claim if you wish`;

  const txt = [];
  if (claimedBySomeoneElse) txt.push(fullyClaimedBySomeoneElseText);
  if (buyerIds.length > 1 && claimedByCurrentUser) txt.push(claimedByUserAndOthersText);
  if (buyerIds.length === 1 && claimedByCurrentUser) txt.push(claimedByUserText);
  if (fullyClaimed || partiallyClaimed) txt.push(leftAvailableText);

  const footerText = txt.join(". ");
  const footerTextNode = (
    <Text textAlign="end" size="12px" color={dark ? "light-6" : "dark-6"} style={{ fontFamily: "HPSimplified" }} children={footerText} />
  );

  return (
    <SRoundedCard
      muted={fullyClaimed}
      elevation={!dark && !fullyClaimed ? "xsmall" : "none"}
      justify="between"
      pad="small"
      width="100%"
      style={{ position: "relative" }}
    >
      <Box direction="row" gap="small">
        <Touchable onTouch={onView} onMouseEnter={onHover} onMouseLeave={onLeave}>
          <Box direction="row" gap={isMobile ? "xsmall" : "small"}>
            <Box width={isMobile ? "56px" : "90px"} height={isMobile ? "56px" : "90px"} flex={{ shrink: 0 }} round="4px" overflow="hidden">
              <LystItemCardImage thumburl={lystItem.image?.downloadUrl} size={isMobile ? "56px" : "90px"} />
            </Box>
            <Box>
              <SLystItemCardHeading limitHeight margin={{ top: "xxsmall" }} bold={!fullyClaimed} underline={isHoverActive}>
                {lystItem.name}
              </SLystItemCardHeading>
              <Text
                color="accent-1"
                size={isMobile ? "12px" : "small"}
                margin={{ top: "xxsmall" }}
                children={`Quantity: ${lystItem.quantity}`}
              />
            </Box>
          </Box>
        </Touchable>
        <ViewBuyerElement onClick={claimItem} width={{ min: "60px" }} align="end" justify="start" style={{ display: "flex" }}>
          <Box width="50px" height="50px" border={!fullyClaimed} round="8px" align="center" justify="center" pad={{ top: "xxsmall" }}>
            {fullyClaimed ? (
              <div
                style={{ opacity: 0.5 }}
                children={<Icon accessibilityLabel="Claimed icon" icon={buyerIds.length > 1 ? "people" : "person"} size={24} />}
              />
            ) : partiallyClaimed ? (
              <div style={{ opacity: 0.5 }} children={<Icon accessibilityLabel="Claimed icon" icon="person-add" size={24} />} />
            ) : null}
          </Box>
        </ViewBuyerElement>
      </Box>
      {footerText && (
        <Box
          align="end"
          border={{ color: "rgba(0,0,0,0.1)", side: "top" }}
          margin={!isMobile ? { horizontal: "small", bottom: "xxsmall" } : { top: "small" }}
          style={!isMobile ? { position: "absolute", bottom: 0, right: 0, width: "calc(100% - (32px + 90px))" } : undefined}
        >
          {claimItem ? <Button plain onClick={claimItem} children={footerTextNode} /> : footerTextNode}
        </Box>
      )}
    </SRoundedCard>
  );
};

export default LystItemHorizontalCard;
