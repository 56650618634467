import { ILystItem, IBuyer } from "../@types";

export interface IReducerState {
  buyers: {
    [id: string]: IBuyer;
  };
  buyersByLystItemId: {
    [id: string]: string[];
  };
  allItems: {
    [id: string]: ILystItem;
  };
  orderByLystId: {
    [lystId: string]: string[];
  };
  claimedOrderByLystId: {
    [lystId: string]: string[];
  };
}

export const fetchItemSuccess = (lystItem: ILystItem) => ({
  type: "lystItems/FETCH_ITEM" as "lystItems/FETCH_ITEM",
  payload: lystItem,
});

export const removeItem = (lystItemId: string) => ({
  type: "lystItems/REMOVE_ITEM" as "lystItems/REMOVE_ITEM",
  payload: lystItemId,
});

export const setOrderForLyst = (lystId: string, ids: string[]) => ({
  type: "lystItems/SET_ORDER_WITHIN_LYST" as "lystItems/SET_ORDER_WITHIN_LYST",
  payload: { lystId, ids },
});

export const setClaimedLystOrder = (lystId: string, ids: string[]) => ({
  type: "lystItems/SET_CLAIMED_ORDER" as "lystItems/SET_CLAIMED_ORDER",
  payload: { lystId, ids },
});

export const fetchBuyerSuccess = (lystItemId: string, buyer: IBuyer & { id: string }) => ({
  type: "lystItems/FETCH_BUYER_SUCCESS" as "lystItems/FETCH_BUYER_SUCCESS",
  payload: { lystItemId, buyer },
});

export const deleteBuyerSuccess = (lystItemId: string, buyerId: string) => ({
  type: "lystItems/DELETE_BUYER_SUCCESS" as "lystItems/DELETE_BUYER_SUCCESS",
  payload: { lystItemId, buyerId },
});

type TAction =
  | ReturnType<typeof fetchItemSuccess>
  | ReturnType<typeof setOrderForLyst>
  | ReturnType<typeof removeItem>
  | ReturnType<typeof setClaimedLystOrder>
  | ReturnType<typeof fetchBuyerSuccess>
  | ReturnType<typeof deleteBuyerSuccess>;

const initialState: IReducerState = {
  buyers: {},
  buyersByLystItemId: {},
  allItems: {},
  orderByLystId: {},
  claimedOrderByLystId: {},
};

function itemReducer(allItems: IReducerState["allItems"], action: ReturnType<typeof fetchItemSuccess> | ReturnType<typeof removeItem>) {
  switch (action.type) {
    case "lystItems/FETCH_ITEM":
      return {
        ...allItems,
        [action.payload.id]: action.payload,
      };
    case "lystItems/REMOVE_ITEM":
      const dupedAllItems = { ...allItems };
      delete dupedAllItems[action.payload];
      return dupedAllItems;
  }
}

function buyersMapReducer(
  buyers: IReducerState["buyers"] = {},
  action: ReturnType<typeof fetchBuyerSuccess> | ReturnType<typeof deleteBuyerSuccess>
) {
  switch (action.type) {
    case "lystItems/FETCH_BUYER_SUCCESS":
      return {
        ...buyers,
        [action.payload.buyer.id]: action.payload.buyer,
      };
    case "lystItems/DELETE_BUYER_SUCCESS":
      return Object.entries(buyers).reduce(
        (accum, [buyerId, buyer]) => ({
          ...accum,
          ...(action.payload.buyerId !== buyerId ? { [buyerId]: buyer } : {}),
        }),
        {} as { [id: string]: IBuyer }
      );
  }
}

function buyersByLystIdReducer(
  state: IReducerState["buyersByLystItemId"],
  action: ReturnType<typeof fetchBuyerSuccess> | ReturnType<typeof deleteBuyerSuccess>
) {
  const { lystItemId } = action.payload;
  const buyerIds = state[lystItemId] || [];

  switch (action.type) {
    case "lystItems/FETCH_BUYER_SUCCESS":
      return {
        ...state,
        [lystItemId]: buyerIds.includes(action.payload.buyer.id) ? buyerIds : [...buyerIds, action.payload.buyer.id],
      };
    case "lystItems/DELETE_BUYER_SUCCESS":
      return {
        ...state,
        [lystItemId]: buyerIds.filter(buyerId => buyerId !== action.payload.buyerId),
      };
  }
}

export default function LystItemsReducer(state = initialState, action: TAction) {
  switch (action.type) {
    case "lystItems/FETCH_ITEM":
    case "lystItems/REMOVE_ITEM":
      return {
        ...state,
        allItems: itemReducer(state.allItems, action),
      };
    case "lystItems/FETCH_BUYER_SUCCESS":
    case "lystItems/DELETE_BUYER_SUCCESS":
      return {
        ...state,
        buyers: buyersMapReducer(state.buyers, action),
        buyersByLystItemId: buyersByLystIdReducer(state.buyersByLystItemId, action),
      };
    case "lystItems/SET_ORDER_WITHIN_LYST":
      return {
        ...state,
        orderByLystId: {
          ...state.orderByLystId,
          [action.payload.lystId]: action.payload.ids,
        },
      };
    case "lystItems/SET_CLAIMED_ORDER":
      return {
        ...state,
        claimedOrderByLystId: {
          ...state.claimedOrderByLystId,
          [action.payload.lystId]: action.payload.ids,
        },
      };
    default:
      return state;
  }
}
