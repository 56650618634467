import React, { FC } from "react";
import { ReactComponent as PresentSphere } from "../assets/icons/present_sphere.svg";
import SObjectFitImage from "../styled-components/object-fit-image";
import { Box } from "grommet";
import { useTheme } from "styled-components";

const LystItemCardImage: FC<{ thumburl?: string; size?: string }> = ({ thumburl, size = "90px" }) => {
  const { dark } = useTheme();
  const defaultImageSize = `${parseInt(size, 10) - parseInt(size, 10) * 0.4}px`;
  return (
    <>
      {thumburl ? (
        <SObjectFitImage src={thumburl} />
      ) : (
        <Box fill background={dark ? "#1a1a1a" : "light-1"} align="center" justify="center">
          <Box width={defaultImageSize} height={defaultImageSize} style={{ opacity: 0.5 }}>
            <PresentSphere color={dark ? "white" : "black"} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default LystItemCardImage;
