import React from "react";
import { Heading, Paragraph, Box } from "grommet";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../assets/icons/wishlystlogo.svg";
import { useTheme } from "styled-components";

const Terms = () => {
  const { dark, global } = useTheme();
  return (
    <Box pad="medium">
      <Link to="/">
        <Box width="280px" margin={{ bottom: "large" }}>
          <Logo color={dark ? "white" : global?.colors?.brand?.toString() || "black"} />
        </Box>
      </Link>

      <Heading level={2}>Fluxion Labs Ltd Terms of Service</Heading>
      <Heading level={3}>1. Terms</Heading>
      <Paragraph fill>
        By accessing the website at <Link to="/">http://wishlyst.co.uk</Link>, you are agreeing to be bound by these terms of service, all
        applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not
        agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are
        protected by applicable copyright and trademark law.
      </Paragraph>
      <Heading level={3}>2. Use Licence</Heading>
      <ol type="a">
        <li>
          Permission is granted to temporarily download one copy of the materials (information or software) on Fluxion Labs Ltd's website
          for personal, non-commercial transitory viewing only. This is the grant of a licence, not a transfer of title, and under this
          licence you may not:
          <ol type="i">
            <li>modify or copy the materials;</li>
            <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
            <li>attempt to decompile or reverse engineer any software contained on Fluxion Labs Ltd's website;</li>
            <li>remove any copyright or other proprietary notations from the materials; or</li>
            <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
          </ol>
        </li>
        <li>
          This licence shall automatically terminate if you violate any of these restrictions and may be terminated by Fluxion Labs Ltd at
          any time. Upon terminating your viewing of these materials or upon the termination of this licence, you must destroy any
          downloaded materials in your possession whether in electronic or printed format.
        </li>
      </ol>
      <Heading level={3}>3. Disclaimer</Heading>
      <ol type="a">
        <li>
          The materials on Fluxion Labs Ltd's website are provided on an 'as is' basis. Fluxion Labs Ltd makes no warranties, expressed or
          implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of
          merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
        </li>
        <li>
          Further, Fluxion Labs Ltd does not warrant or make any representations concerning the accuracy, likely results, or reliability of
          the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.
        </li>
      </ol>
      <Heading level={3}>4. Limitations</Heading>
      <Paragraph fill>
        In no event shall Fluxion Labs Ltd or its suppliers be liable for any damages (including, without limitation, damages for loss of
        data or profit, or due to business interruption) arising out of the use or inability to use the materials on Fluxion Labs Ltd's
        website, even if Fluxion Labs Ltd or a Fluxion Labs Ltd authorised representative has been notified orally or in writing of the
        possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability
        for consequential or incidental damages, these limitations may not apply to you.
      </Paragraph>
      <Heading level={3}>5. Accuracy of materials</Heading>
      <Paragraph fill>
        The materials appearing on Fluxion Labs Ltd's website could include technical, typographical, or photographic errors. Fluxion Labs
        Ltd does not warrant that any of the materials on its website are accurate, complete or current. Fluxion Labs Ltd may make changes
        to the materials contained on its website at any time without notice. However Fluxion Labs Ltd does not make any commitment to
        update the materials.
      </Paragraph>
      <Heading level={3}>6. Links</Heading>
      <Paragraph fill>
        Fluxion Labs Ltd has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked
        site. The inclusion of any link does not imply endorsement by Fluxion Labs Ltd of the site. Use of any such linked website is at the
        user's own risk.
      </Paragraph>
      <Heading level={3}>7. Modifications</Heading>
      <Paragraph fill>
        Fluxion Labs Ltd may revise these terms of service for its website at any time without notice. By using this website you are
        agreeing to be bound by the then current version of these terms of service.
      </Paragraph>
      <Heading level={3}>8. Governing Law</Heading>
      <Paragraph fill>
        These terms and conditions are governed by and construed in accordance with the laws of Essex and you irrevocably submit to the
        exclusive jurisdiction of the courts in that State or location.
      </Paragraph>
    </Box>
  );
};

export default Terms;
