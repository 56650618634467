import { useState, useEffect, useRef } from "react";
import algoliasearch, { SearchClient } from "algoliasearch";
import { functions } from "firebase/app";
import { useDispatch } from "react-redux";
import { SearchResponse } from "@algolia/client-search";

import { algoliaWishLystSubscribersSearchKeySelector, algoliaWishLystSubscribersSearchKeyFetchPredicateSelector } from "../../../selectors";
import { IAlgoliaUserProfile } from "../../../@types";
import { getSecuredWishlystUserSearchKey, getSecuredWishlystUserSearchKeySuccess } from "../../../store/lysts";
import { useStateSelector } from "../../../store";

const useAlgoliaUserSearch = (wishlystId: string, maxResults: number) => {
  const { current: getSearchKey } = useRef(functions().httpsCallable("getSecuredWishlystUserSubscribedSearchApiKey"));
  const dispatch = useDispatch();
  const wishlystSearchKey = useStateSelector(algoliaWishLystSubscribersSearchKeySelector);
  const isWishlystSearchKeyPending = useStateSelector(algoliaWishLystSubscribersSearchKeyFetchPredicateSelector);
  const sessionStorageKey = `wishlyst@wishlyst_${wishlystId}_search_key`;
  const indexName = "dev_users";
  const [algoliaClient, setAlgoliaClient] = useState<SearchClient | null>(null);
  const [results, setResults] = useState<SearchResponse<IAlgoliaUserProfile>["hits"]>([]);

  useEffect(() => {
    (async function getWishlystSearchKey() {
      if (!wishlystSearchKey && !isWishlystSearchKeyPending) {
        const searchKey = window.sessionStorage.getItem(sessionStorageKey);
        if (!searchKey) {
          dispatch(getSecuredWishlystUserSearchKey(wishlystId));
          const { data } = await getSearchKey({ wishlystId });
          window.sessionStorage.setItem(sessionStorageKey, data);
          dispatch(getSecuredWishlystUserSearchKeySuccess(wishlystId, data));
        } else {
          dispatch(getSecuredWishlystUserSearchKeySuccess(wishlystId, searchKey));
        }
      }
    })();
  });

  useEffect(() => {
    if (wishlystSearchKey && process.env.REACT_APP_ALGOLIA_APPID) {
      const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APPID, wishlystSearchKey);
      setAlgoliaClient(client);
    }
  }, [wishlystSearchKey]);

  async function queryUserIndex(searchTerms: string) {
    if (!searchTerms) return setResults([]);
    if (!algoliaClient) return;

    const { results } = await algoliaClient.multipleQueries<IAlgoliaUserProfile>([
      {
        indexName,
        query: `otherNames.displayName:${searchTerms}`,
        params: {
          filters: `otherNames.wishlystId:${wishlystId}`,
          hitsPerPage: maxResults,
        },
      },
      {
        indexName,
        query: `displayName:${searchTerms}`,
        params: {
          hitsPerPage: maxResults,
        },
      },
    ]);
    const allHits = results.reduce((hits, resultSet) => [...hits, ...resultSet.hits], [] as SearchResponse<IAlgoliaUserProfile>["hits"]);
    setResults(allHits);
  }

  return {
    results,
    search: queryUserIndex,
    isPending: isWishlystSearchKeyPending,
  };
};

export default useAlgoliaUserSearch;
