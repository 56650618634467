import React, { FC } from "react";
import { Box, Text, Button, Anchor } from "grommet";
import { Checkmark, NewWindow } from "grommet-icons";

import { ILystItem } from "../../@types";
import LystItemCard from "../lyst-item-card";
import { useStateSelector } from "../../store";
import { SClippedLinkText } from "../../styled-components/clipped-link";

interface IProps {
  lystItem: ILystItem;
  onClaim: () => void;
  onRemoveClaim?: () => void;
  onView?: () => any;
}

export const LystItemClaimCard: FC<IProps> = ({ lystItem, onClaim, onView, onRemoveClaim }) => {
  const account = useStateSelector(({ auth }) => auth.account);
  const { url: href, quantity, buyerIds = [], totalClaimed } = lystItem;
  const multiItem = quantity > 1;
  const hasAnExistingClaim = account?.uid ? buyerIds.includes(account.uid) : false;
  const claimedCount = totalClaimed || 0;
  const fullyClaimed = claimedCount === quantity;

  return (
    <LystItemCard lystItem={lystItem} muted={fullyClaimed} elevation={claimedCount > 0 ? "none" : undefined} onView={onView}>
      <Box margin={{ top: "xxsmall" }}>
        {href && (
          <Anchor href={href} target="_blank" margin={{ bottom: "xsmall" }}>
            <Box direction="row" as="span" gap="xsmall" align="center">
              <SClippedLinkText>View Item</SClippedLinkText>
              <NewWindow size="small" color="#4f93fb" />
            </Box>
          </Anchor>
        )}
        {fullyClaimed && !hasAnExistingClaim && (
          <Text size="12px" color="dark-6" textAlign="end">
            This item has been claimed by someone else
          </Text>
        )}
        {!fullyClaimed && (
          <Box gap="xxsmall" align="end">
            <Box direction="row" gap="small" align="center">
              <Text color="dark-6" size="12px" textAlign="end" children={`${lystItem.quantity - claimedCount} available`} />
              <Button size="small" alignSelf="end" label={hasAnExistingClaim ? "Edit claim" : "Claim"} primary onClick={onClaim} />
            </Box>
            {hasAnExistingClaim && multiItem && claimedCount > 0 && (
              <Text color="dark-6" size="12px" textAlign="end" children="You have claimed this item, claim more if you wish" />
            )}
          </Box>
        )}
        {fullyClaimed && hasAnExistingClaim && (
          <Box gap="xxsmall" align="end">
            <Box direction="row" gap="small">
              <Box margin={{ top: "xxsmall" }} children={<Checkmark size="small" />} />
              <Text
                color="dark-6"
                size="12px"
                textAlign="end"
                children={`You${buyerIds.length > 1 ? " and others," : ""} have claimed this item`}
              />
            </Box>
            {onRemoveClaim && (
              <Button size="small" plain label={<Text size="small">Remove claim</Text>} color="status-critical" onClick={onRemoveClaim} />
            )}
          </Box>
        )}
      </Box>
    </LystItemCard>
  );
};

export default LystItemClaimCard;
