import React, { FC, useState, useEffect } from "react";
import { ILystItem, IBuyer } from "../@types";
import useLystItemActions from "../hooks/use-lyst-item-actions";
import Modal from "./modal";
import { Heading, Button } from "grommet";
import { useStateSelector } from "../store";
import { firestore } from "firebase/app";

interface IProps {
  lystItem: ILystItem;
  onClose: () => void;
}

const UnclaimModal: FC<IProps> = ({ lystItem, onClose }) => {
  const { account } = useStateSelector(({ auth }) => auth);
  const [currentClaim, setCurrentClaim] = useState<null | firestore.DocumentSnapshot>(null);
  const { removeClaim, getUserClaimSnapshot } = useLystItemActions(lystItem.wishlystId, lystItem.id);

  useEffect(() => {
    getAndSetUserClaim();

    async function getAndSetUserClaim() {
      if (!account) return;
      const snap = await getUserClaimSnapshot(account.uid);
      if (snap) setCurrentClaim(snap);
    }
  }, [account, getUserClaimSnapshot]);

  const onDeleteClaim = async () => {
    if (currentClaim) {
      const { userId, count, displayName } = currentClaim.data() as IBuyer;
      await removeClaim(currentClaim.id, { userId, count, displayName });
    }
    onClose();
  };

  if (!currentClaim) return null;

  return (
    <Modal title="Remove claim" onClose={onClose}>
      <Heading level={5}>Are you sure you want to remove your claim of this item?</Heading>
      <Button primary label="Remove claim" onClick={onDeleteClaim} />
    </Modal>
  );
};

export default UnclaimModal;
