import React, { FC, useState } from "react";
import { Text, Box, Heading, RadioButton, Button, ThemeContext } from "grommet";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTheme } from "styled-components";

import { slugify } from "../../utils/slugify";
import useLystItemActions from "../../hooks/use-lyst-item-actions";
import Modal, { IProps as ModalProps } from "../modal";
import ClaimInfoList from "../claim-info-list";
import { useStateSelector } from "../../store";
import UserSearch, { SelectedUser } from "./user-search";
import { AnonymousForm } from "./anonymous-form";

interface IProps extends Omit<ModalProps, "title"> {
  lystItemId: string;
}

type MeOptionType = { isSelected: boolean; onSelect: () => any };
const MeOption: FC<MeOptionType> = ({ isSelected, onSelect }) => (
  <Box direction="row" gap="medium" align="start">
    <RadioButton name="user-search" checked={isSelected} onChange={onSelect} />
    <Text style={{ opacity: isSelected ? 1 : 0.7 }}>Myself</Text>
  </Box>
);

const EditableClaimItemModal: FC<IProps> = ({ onClose, lystItemId }) => {
  const { dark } = useTheme();
  const account = useStateSelector(({ auth }) => auth.account);
  const lystItem = useStateSelector(state => state.lystItems.allItems[lystItemId]);
  const { createClaim, removeClaim, updateClaim, getUserClaimSnapshot } = useLystItemActions(lystItem.wishlystId, lystItem.id);
  const { totalClaimed } = lystItem;
  const hasBuyers = !!totalClaimed && totalClaimed > 0;
  const completelyClaimed = totalClaimed && totalClaimed >= lystItem.quantity;

  const [selectedBuyerOption, setSelectedBuyerOption] = useState<"me" | "user" | "anonymous">("me");
  const [selectedUsers, setSelectedUsers] = useState<SelectedUser[]>([]);

  const validationSchema = Yup.object().shape({ displayName: Yup.string().required() });
  const anonymousForm = useFormik({ validateOnMount: true, initialValues: { displayName: "" }, validationSchema, onSubmit: () => {} });

  const removeSelectedUser = (userId: string) => {
    setSelectedUsers(selectedUsers.filter(({ id }) => id !== userId));
  };

  const selectUser = (selectedUser: SelectedUser) => {
    const exists = selectedUsers.findIndex(({ id }) => id === selectedUser.id) >= 0;
    if (!exists) setSelectedUsers([...selectedUsers, selectedUser]);
  };

  const addFormIsValid = () => {
    if (selectedBuyerOption === "me") return true;
    if (selectedBuyerOption === "user") return selectedUsers.length > 0;
    if (selectedBuyerOption === "anonymous") return anonymousForm.isValid;
    else return false;
  };

  const anonClaim = async (userId: string, count: number, displayName: string) => {
    const snapDoc = await getUserClaimSnapshot(userId);
    if (snapDoc?.exists) return updateClaim(snapDoc.id, { count, displayName }, { userId, displayName });
    return createClaim(count, userId, displayName);
  };

  const userClaim = async (userId: string, count: number, displayName?: string) => {
    const snapDoc = await getUserClaimSnapshot(userId);
    if (snapDoc?.exists) {
      const name = displayName || snapDoc.data().displayName;
      return updateClaim(snapDoc.id, { count, displayName: name }, { userId, displayName: name });
    }
    return createClaim(count, userId, displayName);
  };

  const onAddSubmit = () => {
    if (!account) return;
    if (selectedBuyerOption === "me") return userClaim(account.uid, 1);
    if (selectedBuyerOption === "user") {
      return Promise.all(
        selectedUsers.map(({ _new, ...selectedUser }) => {
          return _new ? anonClaim(selectedUser.id, 1, selectedUser.displayName) : userClaim(selectedUser.id, 1, selectedUser.displayName);
        })
      );
    }
    if (selectedBuyerOption === "anonymous") {
      const displayName = anonymousForm.values.displayName;
      const userId = slugify(displayName);
      return anonClaim(userId, 1, displayName).then(() => anonymousForm.resetForm());
    } else {
      return false;
    }
  };

  return (
    <Modal title="Claim item" onClose={onClose}>
      <Heading level={5} children="Who's buying it?" />
      <ClaimInfoList
        lystItem={lystItem}
        showCount={lystItem.quantity > 1}
        onUpdateCount={(buyId, count) => updateClaim(buyId, { count })}
        onDeleteBuyer={removeClaim}
      />

      {hasBuyers && !completelyClaimed && <Heading level={5}>Add more buyers:</Heading>}
      {!completelyClaimed && (
        <ThemeContext.Extend value={{ formField: { label: { margin: { top: "0", left: "0" } } } }}>
          <Box pad="medium" background={dark ? "dark-1" : "light-1"} style={{ borderRadius: 12 }}>
            <Box pad={{ right: "large" }}>
              <Box margin={{ bottom: "large" }}>
                <MeOption isSelected={selectedBuyerOption === "me"} onSelect={() => setSelectedBuyerOption("me")} />
              </Box>
              <Box margin={{ bottom: "large" }}>
                <UserSearch
                  isSelected={selectedBuyerOption === "user"}
                  onSelect={() => setSelectedBuyerOption("user")}
                  selectedUsers={selectedUsers}
                  removeSelectedUser={removeSelectedUser}
                  onSelectUser={selectUser}
                  wishlystId={lystItem.wishlystId}
                  hideInput={selectedUsers.length === lystItem.quantity - totalClaimed}
                />
              </Box>
              <Box margin={{ bottom: "medium" }}>
                <AnonymousForm
                  name="displayName"
                  value={anonymousForm.values.displayName}
                  error={anonymousForm.touched.displayName ? anonymousForm.errors.displayName : undefined}
                  isSelected={selectedBuyerOption === "anonymous"}
                  onSelect={() => setSelectedBuyerOption("anonymous")}
                  onChange={anonymousForm.handleChange}
                />
              </Box>
            </Box>
            <Button primary label="Add buyer" alignSelf="end" disabled={!addFormIsValid()} onClick={onAddSubmit} />
          </Box>
        </ThemeContext.Extend>
      )}
    </Modal>
  );
};

export default EditableClaimItemModal;
