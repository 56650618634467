import { IBuyer, IUser, ILystItem, ILyst } from "../@types";
import { auth, firestore } from "firebase/app";
import { db } from "../firebase";
import { useStateSelector } from "../store";
import { useState, useEffect } from "react";

const useLystItemActions = (lystId: string, lystItemId: string) => {
  const lystItemsRef = db.collection(`lystItems`);
  const lystItemRef = lystItemsRef.doc(lystItemId);
  const lystItem = useStateSelector(state => state.lystItems.allItems[lystItemId]);
  const [isLystOwner, setIsLystOwner] = useState(false);

  useEffect(() => {
    db.doc(`lysts/${lystItem.wishlystId}`)
      .get()
      .then(snap => {
        if (snap.exists) {
          setIsLystOwner((snap.data() as ILyst)._private.owner === auth().currentUser?.uid);
        }
      });
  }, [lystItem.wishlystId]);

  const getUserClaimSnapshot = async (userId: string) => {
    const snapshot = await lystItemRef
      .collection("buyers")
      .where("userId", "==", userId)
      .limit(1)
      .get();
    if (snapshot.empty) return null;
    return snapshot.docs[0];
  };

  const createClaim = async (count: number, claimantId: string, displayName?: string) => {
    const claimantIsCurrentUser = auth().currentUser?.uid === claimantId;
    const user = await db.doc(`users/${claimantId}`).get();
    const isAnonymous = !user.exists;
    const userData = user.data() as IUser;
    const defaultDisplayName = userData ? userData.displayName : undefined;
    const displayNameToUse = displayName || defaultDisplayName;
    const isDefaultName = displayNameToUse === defaultDisplayName;

    if (!displayNameToUse) throw Error("Displayname is a required argument for claims");

    const buyerDetails: IBuyer = {
      confirmed: isAnonymous ? true : claimantIsCurrentUser,
      userId: claimantId,
      displayName: displayNameToUse,
      count,
      isAnonymous,
      useDefaultName: isDefaultName,
    };

    const batch = db.batch();
    batch.set(lystItemRef.collection("buyers").doc(), buyerDetails);
    if (isLystOwner) {
      batch.update(lystItemRef, {
        totalClaimed: firestore.FieldValue.increment(buyerDetails.count),
        buyerDisplayNames: firestore.FieldValue.arrayUnion(displayNameToUse),
        buyerIds: firestore.FieldValue.arrayUnion(claimantId),
      });
    }
    await batch.commit();
    return true;
  };

  const updateClaim = async (
    buyerDocId: string,
    buyDetails: Partial<Omit<IBuyer, "userId" | "confirmed" | "isAnonymous">>,
    optimisticData?: { userId?: string; displayName?: string }
  ) => {
    db.runTransaction(async transaction => {
      const buyerRef = lystItemRef.collection("buyers").doc(buyerDocId);
      const buyerSnapshot = await transaction.get(buyerRef);
      const lystItemSnapshot = await transaction.get(lystItemRef);
      const previousBuyerData = buyerSnapshot.data() as IBuyer | null;
      const lystItemData = lystItemSnapshot.data() as ILystItem | null;

      const previousTotalClaimed = lystItemData ? lystItemData.totalClaimed : 0;
      const previousBuyerCount = previousBuyerData ? previousBuyerData.count : 0;

      transaction.update(buyerRef, buyDetails);

      if (lystItemSnapshot.exists && buyDetails.count && isLystOwner) {
        const newDisplayName = !!buyDetails.displayName && buyDetails.displayName !== previousBuyerData?.displayName;
        if (newDisplayName && previousBuyerData?.displayName) {
          transaction.update(lystItemRef, { buyerDisplayNames: firestore.FieldValue.arrayRemove(previousBuyerData.displayName) });
          transaction.update(lystItemRef, { buyerDisplayNames: firestore.FieldValue.arrayUnion(buyDetails.displayName) });
        }
        transaction.update(lystItemRef, { totalClaimed: previousTotalClaimed - previousBuyerCount + buyDetails.count });
      }
    });
  };

  const removeClaim = (buyerDocId: string, optimisticData?: { count: number; userId?: string; displayName?: string }) => {
    return db.runTransaction(async transaction => {
      const lystItemSnapshot = await transaction.get(lystItemRef);
      const buyerSnapshot = await transaction.get(lystItemRef.collection("buyers").doc(buyerDocId));
      const buyerDetails = buyerSnapshot.exists ? (buyerSnapshot.data() as IBuyer) : null;

      transaction.delete(buyerSnapshot.ref);
      if (buyerDetails && isLystOwner) {
        transaction.update(lystItemSnapshot.ref, {
          totalClaimed: firestore.FieldValue.increment(-Math.abs(buyerDetails.count)),
          buyerDisplayNames: firestore.FieldValue.arrayUnion(buyerDetails.displayName),
          buyerIds: firestore.FieldValue.arrayUnion(buyerDetails.userId),
        });
      }
    });
  };

  return {
    createClaim,
    updateClaim,
    removeClaim,
    getUserClaimSnapshot,
  };
};

export default useLystItemActions;
