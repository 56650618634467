import React, { FC, useContext } from "react";
import LystItemCard from "../lyst-item-card";
import { ILystItem } from "../../@types";
import { Box, Text, ResponsiveContext, Button } from "grommet";
import { StatusGood } from "grommet-icons";
import ClaimInfo from "../claim-info";

interface IProps {
  lystItem: ILystItem;
  onView: () => void;
  onClaim: () => void;
}

export const LystItemOwnerCard: FC<IProps> = ({ lystItem, onView, onClaim }) => {
  const isMobile = useContext(ResponsiveContext) === "small";
  const { totalClaimed, buyerDisplayNames } = lystItem;
  const fullyClaimed = totalClaimed === lystItem.quantity;

  return (
    <LystItemCard key={lystItem.id} lystItem={lystItem} onView={onView} muted={fullyClaimed}>
      <Box direction="row">
        {totalClaimed && totalClaimed > 0 ? (
          <Box onClick={onClaim}>
            <Box direction="row" align="center" gap={isMobile ? "xxsmall" : "xsmall"}>
              <StatusGood color={fullyClaimed ? "status-ok" : undefined} />
              <Text as="div" size="small">
                {buyerDisplayNames && <ClaimInfo buyerNames={buyerDisplayNames} textProps={{ size: "small", weight: "bold" }} />}
              </Text>
            </Box>
          </Box>
        ) : (
          <Button label="Mark as claimed" size="small" color="status-info" onClick={onClaim} />
        )}
      </Box>
    </LystItemCard>
  );
};

export default LystItemOwnerCard;
