import React, { FC, ReactElement, useContext, useState } from "react";
import { SRoundedCard } from "../styled-components/rounded-card";
import { Box, Text, BoxTypes, ResponsiveContext } from "grommet";
import { ILystItem } from "../@types";
import styled, { useTheme } from "styled-components";
import { Touchable } from "gestalt";
import SLystItemCardHeading from "../styled-components/lyst-item-card-heading";
import LystItemCardImage from "./lyst-item-card-image";

interface IProps extends BoxTypes {
  lystItem: ILystItem;
  muted?: boolean;
  footer?: ReactElement;
  onView?: () => void;
}

const SImgContainer = styled(Box).attrs(({ theme: { dark } }) => ({
  border: { color: dark ? "dark-2" : "light-3" },
}))`
  overflow: hidden;
  height: 25vh;
  max-height: 150px;
  @media (min-width: 640px) {
    max-height: 200px;
  }
  @media (min-width: 1200px) {
    max-height: 250px;
  }
  @media (min-width: 1440px) {
    max-height: 300px;
  }
`;

const LystItemCard: FC<IProps> = ({ lystItem, muted, footer, children, onView, ...props }) => {
  const { dark } = useTheme();
  const child = footer || children;
  const isMobile = useContext(ResponsiveContext) === "small";
  const [isHoverActive, setIsHoverActive] = useState(false);

  const onHover = () => setIsHoverActive(true);
  const onLeave = () => setIsHoverActive(false);

  return (
    <SRoundedCard muted={muted} elevation={!muted && !dark ? "xsmall" : "none"} pad="small" {...props}>
      <Touchable onTouch={!!onView ? onView : () => void 0} onMouseEnter={onHover} onMouseLeave={onLeave} rounding={2} mouseCursor="zoomIn">
        <SImgContainer>
          <LystItemCardImage thumburl={lystItem.image?.downloadUrl} />
        </SImgContainer>
        <SLystItemCardHeading
          margin={{ top: "small" }}
          bold={!muted}
          underline={isHoverActive}
          aria-label={lystItem.name}
          limitHeight
          title={lystItem.name}
        >
          {lystItem.name}
        </SLystItemCardHeading>
        <Text size={isMobile ? "xsmall" : "small"} color="neutral-1">
          Quantity: {lystItem.quantity}
        </Text>
      </Touchable>
      {child && <Box>{child}</Box>}
    </SRoundedCard>
  );
};

export default LystItemCard;
