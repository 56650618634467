import React, { FC, ChangeEvent, useContext } from "react";
import { Box, RadioButton, TextInputProps, ResponsiveContext } from "grommet";
import FieldInput from "../field-input";

interface IAnonymousForm extends Omit<TextInputProps, "value"> {
  value: string;
  isSelected: boolean;
  onSelect: () => any;
  error?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const AnonymousForm: FC<IAnonymousForm> = ({ isSelected, onSelect, error, ...props }) => {
  const isMobile = useContext(ResponsiveContext) === "small";
  return (
    <Box direction="row" gap="medium" align="start">
      <RadioButton name="user-search" value="anonymous" checked={isSelected} onChange={onSelect} />
      <Box {...(!isMobile ? { flex: "grow" } : {})} style={{ opacity: isSelected ? 1 : 0.7 }}>
        <FieldInput {...props} label={"Manually enter thier name if they're not"} error={error} disabled={!isSelected} />
      </Box>
    </Box>
  );
};
