import React, { FC, useState, useContext } from "react";
import { ILystItem } from "../../@types";
import SRoundedCard from "../../styled-components/rounded-card";
import { Box, Text, Button, ResponsiveContext } from "grommet";
import LystItemCardImage from "../lyst-item-card-image";
import { Touchable, Icon } from "gestalt";
import SLystItemCardHeading from "../../styled-components/lyst-item-card-heading";
import { useTheme } from "styled-components";
import { toNameList } from "../claim-info";

interface Props {
  lystItem: ILystItem;
  onView: () => void;
  onClaim?: () => void;
}

export const LystItemOwnerHorizontalCard: FC<Props> = ({ lystItem, onClaim, onView }) => {
  const { dark } = useTheme();
  const isMobile = useContext(ResponsiveContext) === "small";
  const { totalClaimed, quantity, buyerIds = [], buyerDisplayNames = [] } = lystItem;
  const [isHoverActive, setIsHoverActive] = useState(false);
  const fullyClaimed = totalClaimed === quantity;
  const leftAvailableText = fullyClaimed || totalClaimed === 0 ? undefined : `${quantity - totalClaimed} left to claim`;
  const buyerNameList = !!buyerDisplayNames.length ? `claimed by ${toNameList(buyerDisplayNames)}` : undefined;
  const footerText = [...(buyerNameList ? [buyerNameList] : []), ...(leftAvailableText ? [leftAvailableText] : [])].join(". ");

  const onHover = () => setIsHoverActive(true);
  const onLeave = () => setIsHoverActive(false);

  return (
    <SRoundedCard
      muted={fullyClaimed}
      elevation={!dark && !fullyClaimed ? "xsmall" : "none"}
      justify="between"
      pad="small"
      width="100%"
      style={{ position: "relative" }}
    >
      <Box direction="row" gap="small" justify="between">
        <Touchable onTouch={onView} onMouseEnter={onHover} onMouseLeave={onLeave}>
          <Box direction="row" gap={isMobile ? "xsmall" : "small"}>
            <Box width={isMobile ? "56px" : "90px"} height={isMobile ? "56px" : "90px"} flex={{ shrink: 0 }} round="4px" overflow="hidden">
              <LystItemCardImage thumburl={lystItem.image?.downloadUrl} size={isMobile ? "56px" : "90px"} />
            </Box>
            <Box>
              <SLystItemCardHeading limitHeight margin={{ top: "xxsmall" }} bold={!fullyClaimed} underline={isHoverActive}>
                {lystItem.name}
              </SLystItemCardHeading>
              <Text
                color="accent-1"
                size={isMobile ? "12px" : "small"}
                margin={{ top: "xxsmall" }}
                children={`Quantity: ${lystItem.quantity}`}
              />
            </Box>
          </Box>
        </Touchable>
        <Button onClick={onClaim} style={{ display: "flex" }}>
          <Box width="50px" height="50px" border={!fullyClaimed} round="8px" align="center" justify="center">
            {totalClaimed > 0 && (
              <div style={{ opacity: 0.5 }}>
                <Icon accessibilityLabel="Claimed icon" icon={buyerIds.length > 1 ? "people" : "person"} size={24} />
              </div>
            )}
          </Box>
        </Button>
      </Box>
      {footerText && (
        <Box
          border={{ color: "rgba(0,0,0,0.1)", side: "top" }}
          margin={!isMobile ? { horizontal: "small", bottom: "xxsmall" } : { top: "small" }}
          style={!isMobile ? { position: "absolute", bottom: 0, right: 0, width: "calc(100% - (32px + 90px))" } : undefined}
        >
          <Text textAlign="end" size="12px" color={dark ? "light-6" : "dark-6"} children={footerText} />
        </Box>
      )}
    </SRoundedCard>
  );
};

export default LystItemOwnerHorizontalCard;
